<script>
// Allows stubbing BaseLink in unit tests
const BaseLink = 'BaseLink'

export default {
  // Functional components are stateless, meaning they can't
  // have data, computed properties, etc and they have no
  // `this` context.
  functional: true,
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  // Render functions are an alternative to templates
  render(h, { props, $style = {} }) {
    function getRouteTitle(route) {
      return typeof route.title === 'function' ? route.title() : route.title
    }
    function getRouteClass(route) {
      return `nav-route ${route.tag || ''} ${route.class || ''}`
    }

    // Functional components are the only components allowed
    // to return an array of children, rather than a single
    // root node.
    return props.routes.map((route) => (
      <BaseLink
        tag={route.tag || 'span'}
        class={getRouteClass(route)}
        key={route.name}
        to={route.to || route}
        href={route.href}
        exact={route.exact || false}
        exact-active-class={$style.active}
      >
        <a target={route.target || false}>{getRouteTitle(route)}</a>
      </BaseLink>
    ))
  },
}
</script>

<style lang="scss" module>
@import '@design';

// .active a {
//   padding-bottom: 0.3em;
//   color: $color-link-text;
//   text-decoration: none;
//   cursor: default;
//   border-bottom: 2px solid $color-db-purple;
// }
</style>
